<template>
    <header v-if="title !== 'semHeader'">
        <div class="header">
            <transition name="fade">
                <a
                    v-if="hasMenu"
                    @click="
                        drawerVisible = true;
                        getPermissoes();
                    "
                    class="btnMenu"
                >
                    <img src="./assets/img/menu-white.svg" alt="refresh-image" />
                </a>
            </transition>
            <a id="logo" href="/carregamento">
                <img src="./assets/img/logo-minus.png" alt="logo-supermercado" />
            </a>
            <div class="menu">
                <strong>{{ title }}</strong>
            </div>
            <!-- <button id="btnRefresh" :class="{ 'refresh-none': hasNoRefresh }" type="button" @click="sincronizaDados">
                <img src="./assets/img/refresh-white.svg" alt="refresh-image" />
                <span id="attNumber" v-if="getNumerosSync > 0">{{ getNumerosSync }}</span>
            </button> -->
            <button v-if="Refresh" id="btnRefresh" type="button" @click="this.$router.push({ name: 'carregamento' })">
                <img src="./assets/img/refresh-white.svg" alt="refresh-image" />
            </button>
            <a v-if="hasArrow" @click="$router.go(-1)" class="arrow-back">
                <font-awesome-icon :icon="['fas', 'circle-left']" style="color: #ffffff; width: 20px; height: 20px" />
            </a>
        </div>
    </header>

    <template v-if="drawerVisible">
        <transition name="slide" mode="out-in" appear>
            <div class="left-drawer">
                <transition name="slide" mode="out-in">
                    <div class="container_modal_suporte" v-if="modalSuporte">
                        <div class="modal_suporte">
                            <h1 class="titulo_suporte">Deseja iniciar um contato com nosso suporte?</h1>
                            <div class="buttons_suporte">
                                <div class="cancelar_suporte btn_suporte" @click="modalSuporte = false">
                                    <h1>Cancelar</h1>
                                </div>
                                <a
                                    @click="modalSuporte = false"
                                    target="_blank"
                                    class="btn_suporte"
                                    href="https://wa.me/5544997525279?text=Ol%C3%A1%2C%20preciso%20de%20ajuda%20no%20app%20valid."
                                >
                                    Continuar
                                </a>
                            </div>
                        </div>
                    </div>
                </transition>
                <div class="containerMenuDrawer">
                    <a @click="drawerVisible = false" class="btnLogo">
                        <img src="./assets/img/close-white.svg" alt="refresh-image" />
                    </a>
                    <router-link @click="drawerVisible = false" class="btnLogo" :to="{ name: 'menu' }">
                        <img class="menu_logo" src="./assets/img/logo-minus.png" alt="logo" />
                    </router-link>
                </div>
                <div class="container_functions">
                    <div
                        class="container_options"
                        v-if="
                            permissions.tela_importacao_tarefas ||
                            permissions.tela_importacao_gestao_compras ||
                            permissions.tela_importacao_devolucao_lojas ||
                            permissions.tela_importacao_devolucao_fornecedor
                        "
                    >
                        <h1 class="title_options">Importação</h1>
                        <!-- <a
                        @click="
                            [
                                (drawerVisible = false),
                                verifyPage({ title: 'Tarefas', archive_title: 'governanca_gecom' }),
                            ]
                        "
                        class="btnRouter"                        
                            :style="{
                                backgroundColor: checkRoute('tela_importacoes','Tarefas')
                                    ? 'var(--verde)'
                                    : 'transparent',
                            }"
                    >
                        <font-awesome-icon
                            :icon="['fas', 'clipboard-list']"
                            style="color: #fff; width: 20px; height: 20px"
                        />
                        <h1>Tarefas</h1>
                    </a> -->
                        <a
                            @click="
                                [
                                    (drawerVisible = false),
                                    verifyPage(
                                        { title: 'Gestão de Compras', archive_title: 'governanca_gecom' },
                                        'tela_importacoes',
                                    ),
                                ]
                            "
                            class="btnRouter"
                            :style="{
                                backgroundColor: checkRoute('tela_importacoes', 'Gestão de Compras')
                                    ? 'var(--verde)'
                                    : 'transparent',
                            }"
                        >
                            <font-awesome-icon
                                :icon="['fas', 'handshake']"
                                style="color: #fff; width: 20px; height: 20px"
                            />
                            <h1>Gestão de Compras</h1>
                        </a>
                        <a
                            @click="
                                [
                                    (drawerVisible = false),
                                    verifyPage(
                                        { title: 'Devolução Lojas', archive_title: 'governanca_sedev_lojas' },
                                        'tela_importacoes',
                                    ),
                                ]
                            "
                            class="btnRouter"
                            :style="{
                                backgroundColor: checkRoute('tela_importacoes', 'Devolução Lojas')
                                    ? 'var(--verde)'
                                    : 'transparent',
                            }"
                        >
                            <font-awesome-icon
                                :icon="['fas', 'boxes-packing']"
                                style="color: #fff; width: 20px; height: 20px"
                            />
                            <h1>Devolução Lojas</h1>
                        </a>
                        <a
                            @click="
                                [
                                    (drawerVisible = false),
                                    verifyPage(
                                        {
                                            title: 'Devolução Fornecedor',
                                            archive_title: 'governanca_sedev_fornecedor',
                                        },
                                        'tela_importacoes',
                                    ),
                                ]
                            "
                            class="btnRouter"
                            :style="{
                                backgroundColor: checkRoute('tela_importacoes', 'Devolução Fornecedor')
                                    ? 'var(--verde)'
                                    : 'transparent',
                            }"
                        >
                            <font-awesome-icon
                                :icon="['fas', 'industry']"
                                style="color: #fff; width: 20px; height: 20px"
                            />
                            <h1>Devolução Fornecedor</h1>
                        </a>
                    </div>
                    <div
                        class="container_options"
                        v-if="
                            permissions.tela_bloqueio ||
                            permissions.tela_regras_rebaixa ||
                            permissions.tela_regras_retirada
                        "
                    >
                        <h1 class="title_options">Configurações</h1>
                        <a
                            @click="[(drawerVisible = false), this.$router.push({ name: 'produtos_bloqueados' })]"
                            class="btnRouter"
                            :style="{
                                backgroundColor: checkRoute('produtos_bloqueados') ? 'var(--verde)' : 'transparent',
                            }"
                        >
                            <font-awesome-icon :icon="['fas', 'ban']" style="color: #fff; width: 20px; height: 20px" />
                            <h1>Produtos Bloqueados</h1>
                        </a>
                        <a
                            @click="[(drawerVisible = false), this.$router.push({ name: 'regras_rebaixa' })]"
                            class="btnRouter"
                            :style="{
                                backgroundColor: checkRoute('regras_rebaixa') ? 'var(--verde)' : 'transparent',
                            }"
                        >
                            <font-awesome-icon :icon="['fas', 'tag']" style="color: #fff; width: 20px; height: 20px" />
                            <h1>Regras de Rebaixa</h1>
                        </a>
                        <a
                            @click="[(drawerVisible = false), this.$router.push({ name: 'regras_retirada' })]"
                            class="btnRouter"
                            :style="{
                                backgroundColor: checkRoute('regras_retirada') ? 'var(--verde)' : 'transparent',
                            }"
                        >
                            <font-awesome-icon
                                :icon="['fas', 'clock-rotate-left']"
                                style="color: #fff; width: 20px; height: 20px"
                            />
                            <h1>Regras de Retirada</h1>
                        </a>
                    </div>
                    <div
                        class="container_options"
                        v-if="
                            permissions.tela_usuarios_app ||
                            permissions.tela_usuarios_comercial ||
                            permissions.tela_lojas
                        "
                    >
                        <h1 class="title_options">Gestão</h1>
                        <a
                            @click="[(drawerVisible = false), this.$router.push({ name: 'gestao_usuarios' })]"
                            class="btnRouter"
                            :style="{
                                backgroundColor: checkRoute('gestao_usuarios') ? 'var(--verde)' : 'transparent',
                            }"
                        >
                            <font-awesome-icon
                                :icon="['fas', 'user-group']"
                                style="color: #fff; width: 20px; height: 20px"
                            />
                            <h1>Usuários Aplicativo</h1>
                        </a>
                        <a
                            @click="[(drawerVisible = false), this.$router.push({ name: 'gestao_comercial' })]"
                            class="btnRouter"
                            :style="{
                                backgroundColor: checkRoute('gestao_comercial') ? 'var(--verde)' : 'transparent',
                            }"
                        >
                            <font-awesome-icon
                                :icon="['fas', 'cart-shopping']"
                                style="color: #fff; width: 20px; height: 20px"
                            />
                            <h1>Usuários Comercial</h1>
                        </a>
                        <a
                            @click="[(drawerVisible = false), this.$router.push({ name: 'gestao_lojas' })]"
                            class="btnRouter"
                            :style="{
                                backgroundColor: checkRoute('gestao_lojas') ? 'var(--verde)' : 'transparent',
                            }"
                        >
                            <font-awesome-icon :icon="['fas', 'shop']" style="color: #fff; width: 20px; height: 20px" />
                            <h1>Cadastro de Lojas</h1>
                        </a>
                    </div>
                    <div class="container_options" v-if="permissions.tela_validades_cd">
                        <h1 class="title_options">Visão</h1>
                        <a
                            @click="[(drawerVisible = false), this.$router.push({ name: 'enderecamento' })]"
                            class="btnRouter"
                            :style="{
                                backgroundColor: checkRoute('enderecamento') ? 'var(--verde)' : 'transparent',
                            }"
                        >
                            <font-awesome-icon
                                :icon="['fas', 'boxes-stacked']"
                                style="color: #fff; width: 20px; height: 20px"
                            />
                            <h1>Endereçamento</h1>
                        </a>
                    </div>
                    <div
                        class="container_options"
                        v-if="
                            permissions.tela_importacao_lotes ||
                            permissions.tela_importacao_wms ||
                            permissions.tela_importacao_ressarcimento_valores ||
                            permissions.tela_importacao_ressarcimento_fornecedores
                        "
                    >
                        <h1 class="title_options">Importação</h1>
                        <a
                            @click="
                                [
                                    (drawerVisible = false),
                                    verifyPage(
                                        { title: 'Base Lotes', archive_title: 'itens_a_vencer_cd_aux' },
                                        'tela_importacoes_bases',
                                    ),
                                ]
                            "
                            class="btnRouter"
                            :style="{
                                backgroundColor: checkRoute('tela_importacoes_bases', 'Base Lotes')
                                    ? 'var(--verde)'
                                    : 'transparent',
                            }"
                        >
                            <font-awesome-icon
                                :icon="['fas', 'clipboard-check']"
                                style="color: #fff; width: 20px; height: 20px"
                            />
                            <h1>Base Lotes</h1>
                        </a>
                        <a
                            @click="
                                [
                                    (drawerVisible = false),
                                    verifyPage(
                                        { title: 'Base WMS', archive_title: 'endereco_wms_aux' },
                                        'tela_importacoes_bases',
                                    ),
                                ]
                            "
                            class="btnRouter"
                            :style="{
                                backgroundColor: checkRoute('tela_importacoes_bases', 'Base WMS')
                                    ? 'var(--verde)'
                                    : 'transparent',
                            }"
                        >
                            <font-awesome-icon
                                :icon="['fas', 'cubes-stacked']"
                                style="color: #fff; width: 20px; height: 20px"
                            />
                            <h1>Base WMS</h1>
                        </a>
                        <a
                            @click="
                                [
                                    (drawerVisible = false),
                                    verifyPage(
                                        {
                                            title: 'Base Ressarcimento Valores',
                                            archive_title: 'ressarcimento_aux',
                                        },
                                        'tela_importacoes_bases',
                                    ),
                                ]
                            "
                            class="btnRouter"
                            :style="{
                                backgroundColor: checkRoute('tela_importacoes_bases', 'Base Ressarcimento Valores')
                                    ? 'var(--verde)'
                                    : 'transparent',
                            }"
                        >
                            <font-awesome-icon
                                :icon="['fas', 'sack-dollar']"
                                style="color: #fff; width: 20px; height: 20px"
                            />
                            <h1>Base Ressarcimento Valores</h1>
                        </a>
                        <a
                            @click="
                                [
                                    (drawerVisible = false),
                                    verifyPage(
                                        {
                                            title: 'Base Ressarcimento Fornecedores',
                                            archive_title: 'fornecedores_dga_aux',
                                        },
                                        'tela_importacoes_bases',
                                    ),
                                ]
                            "
                            class="btnRouter"
                            :style="{
                                backgroundColor: checkRoute('tela_importacoes_bases', 'Base Ressarcimento Fornecedores')
                                    ? 'var(--verde)'
                                    : 'transparent',
                            }"
                        >
                            <font-awesome-icon
                                :icon="['fas', 'sack-dollar']"
                                style="color: #fff; width: 20px; height: 20px"
                            />
                            <h1>Base Ressarcimento Fornecedores</h1>
                        </a>
                    </div>
                    <div
                        class="container_options"
                        v-if="permissions.tela_recebimento_cd || permissions.tela_expedicao_cd"
                    >
                        <h1 class="title_options">Transferências</h1>
                        <a
                            @click="[(drawerVisible = false), this.$router.push({ name: 'tela_recebimento_cd' })]"
                            class="btnRouter"
                            :style="{
                                backgroundColor: checkRoute('tela_recebimento_cd') ? 'var(--verde)' : 'transparent',
                            }"
                        >
                            <font-awesome-icon
                                :icon="['fas', 'boxes-stacked']"
                                style="color: #fff; width: 20px; height: 20px"
                            />
                            <h1>Recebimento CD</h1>
                        </a>
                        <a
                            @click="[(drawerVisible = false), this.$router.push({ name: 'tela_expedicao_cd' })]"
                            class="btnRouter"
                            :style="{
                                backgroundColor: checkRoute('tela_expedicao_cd') ? 'var(--verde)' : 'transparent',
                            }"
                        >
                            <font-awesome-icon
                                :icon="['fas', 'truck-arrow-right']"
                                style="color: #fff; width: 20px; height: 20px"
                            />
                            <h1>Expedição CD</h1>
                        </a>
                    </div>
                    <!-- <div class="container_options">
                        <h1 class="title_options">Suporte</h1>
                        <div @click="modalSuporte = true" class="btnRouter">
                            <font-awesome-icon
                                :icon="['fab', 'youtube']"
                                style="color: #ffffff; width: 20px; height: 20px"
                            />
                            <h1>Central de Treinamento</h1>
                        </div>
                        <div @click="modalSuporte = true" class="btnRouter">
                            <font-awesome-icon
                                :icon="['fab', 'whatsapp']"
                                style="color: #ffffff; width: 20px; height: 20px"
                            />
                            <h1>Canal de Suporte</h1>
                        </div>
                    </div> -->
                </div>
                <router-link @click="drawerVisible = false" class="btnSair" :to="{ name: 'login' }">
                    <font-awesome-icon
                        :icon="['fas', 'arrow-right-from-bracket']"
                        style="color: #ef6969; width: 30px; height: 30px; margin-right: auto; margin-left: 0"
                    />
                    <h1>Sair</h1>
                </router-link>
            </div>
        </transition>
    </template>

    <template v-if="isLogin">
        <header-principal :hasNoRefresh="true" titulo="Login"></header-principal>
    </template>

    <!-- <ModalIdle v-if="isIdle && !isLogin" /> -->

    <mensagem-alerta @closeModal="closeModal" :mensagem="mensagem"></mensagem-alerta>

    <router-view v-slot="slotProps" v-if="!sincronizing">
        <transition name="slide" mode="out-in">
            <component :is="slotProps.Component"></component>
        </transition>
    </router-view>

    <tela-sync v-if="sincronizing" @closePopUp="closePopUp" :msgSync="msgSync"></tela-sync>

    <template v-if="!isSave && !isLogin">
        <footer v-if="title !== 'semHeader'">
            <div class="footer-logo">
                <img src="./assets/img/logo-minus.png" alt="relogio" />
            </div>
        </footer>
    </template>
</template>

<script>
import HTTP from './http/http.js';
import HeaderPrincipal from './components/HeaderPrincipal';
import MensagemAlerta from './components/MensagemAlerta.vue';
import TelaSync from './components/TelaSync.vue';
import ModalIdle from './components/ModalIdle.vue';

export default {
    components: {
        MensagemAlerta,
        HeaderPrincipal,
        TelaSync,
        ModalIdle,
    },
    data() {
        return {
            title: 'Valid',
            drawerVisible: false,
            hasArrow: false,
            hasNoRefresh: false,
            isSave: false,
            mensagem: {},
            isLogin: false,
            sincronizing: false,
            counter: 0,
            msgSync: 'Sincronizando... Por favor, aguarde.',
            Refresh: false,
            hasMenu: false,
            modalSuporte: false,
            tipoUsuario: '',
            permissions: {},
        };
    },
    mounted() {
        this.tipoUsuario = sessionStorage.getItem('tipoUsuario');
        this.permissions = JSON.parse(sessionStorage.getItem('permissoes'));
    },

    computed: {
        getPermissions() {
            return this.$store.getters.getPermissions;
        },

        getPageImportacoes() {
            return this.$store.getters.getPageImportacoes;
        },

        // isIdle() {
        //     return this.$store.state.idleVue.isIdle;
        // },
    },

    methods: {
        verifyPage(tela, rota) {
            this.$store.dispatch('setPageImportacoes', {
                title: tela.title,
                archive_title: tela.archive_title,
            });
            this.$router.push({
                name: rota,
                state: {
                    title: tela.title,
                    archive_title: tela.archive_title,
                },
            });
        },

        getPermissoes() {
            this.permissions = JSON.parse(sessionStorage.getItem('permissoes'));
        },

        checkRoute(route_name, titleImportacoes) {
            if (this.$route.name === 'tela_importacoes' || this.$route.name === 'tela_importacoes_bases') {
                let pageTitle = this.getPageImportacoes;
                if (pageTitle.title == titleImportacoes) {
                    return true;
                } else {
                    return false;
                }
            } else {
                if (this.$route.name == route_name) {
                    return true;
                } else {
                    return false;
                }
            }
        },

        sincronizaDados() {
            if (navigator.onLine) {
                this.sincronizing = true;

                // this.enviaInclusao();

                setTimeout(() => {
                    this.sincronizing = false;

                    if (this.counter > 0) {
                        this.mensagem = {
                            isSend: true,
                            body: `${this.counter} sincronizações geraram erro. Certifique sua internet e sincronize novamente. `,
                            error: true,
                        };
                    } else {
                        this.mensagem = {
                            isSend: true,
                            body: 'Dados sincronizados com sucesso!',
                            error: false,
                        };
                    }

                    setTimeout(() => {
                        this.mensagem.isSend = false;
                        this.counter = 0;
                    }, 3000);
                }, 4000);
            } else {
                this.mensagem = {
                    isSend: true,
                    body: 'Você precisa estar conectado a internet.',
                    error: true,
                };
            }
        },

        closePopUp() {
            this.sincronizing = false;
        },

        // enviaInclusao() {
        //     let produtosSalvos = JSON.parse(localStorage.getItem('inclusao'));
        //     let loja = sessionStorage.getItem('loja');
        //     localStorage.removeItem('inclusao');

        //     let produtosNaoEnviados = [];

        //     if (produtosSalvos) {
        //         produtosSalvos.forEach((data) => {
        //             HTTP.post(`${loja}/inclusao`, data)
        //                 .then(() => {
        //                     this.$store.dispatch('minusNumeroSync');
        //                 })
        //                 .catch(() => {
        //                     this.counter++;
        //                     produtosNaoEnviados.push(data);

        //                     let dataToLS = JSON.stringify(produtosNaoEnviados);

        //                     localStorage.setItem('inclusao', dataToLS);
        //                 });
        //         });
        //     }
        // },

        closeModal() {
            this.mensagem.isSend = false;
        },
    },
    watch: {
        $route: function (val) {
            this.title = val.meta.title;
            this.hasArrow = val.meta.hasArrow;
            this.hasNoRefresh = val.meta.hasNoRefresh;
            this.Refresh = val.meta.Refresh;
            this.isSave = val.meta.isSave;
            this.isLogin = val.meta.isLogin;
            this.hasMenu = val.meta.hasMenu;
            if (sessionStorage.getItem('tipoUsuario') != null) this.tipoUsuario = sessionStorage.getItem('tipoUsuario');
        },
    },
};
</script>

<style>
:root {
    --verde: #56bb8f;
    --vermelho: #ef6969;
    --branco: #ffffff;
    --preto: #000;
    --cinza: #cdcdcd;
    --cinza-escuro: #5f6368;
    --cinza-claro: #f4f4f4;
    --azul-escuro: #0f1628;
}

* {
    font-family: 'Karla', sans-serif;
    font-size: 18px;
    margin: 0;
    padding: 0;
    text-decoration: none;
    list-style: none;
    box-sizing: border-box;
    font-weight: 400;
}

body {
    width: 100%;
    min-height: 100%;
    scroll-behavior: smooth;
    overscroll-behavior-y: contain;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

.fade-enter-to,
.fade-leave-from {
    opacity: 1;
}

.fade-enter-active,
.fade-leave-active {
    transition: all 0.2s ease;
}

.slide-enter-from,
.slide-leave-to {
    transform: translateX(-100vw);
}

.slide-enter-to,
.slide-leave-from {
    transform: translateX(0px);
}

.slide-enter-active,
.slide-leave-active {
    transition: all 0.4s ease-in-out;
}

.slide-both-enter-from {
    transform: translateX(-100vw);
}

.slide-both-leave-to {
    transform: translateX(100vw);
}

.slide-both-enter-to,
.slide-both-leave-from {
    transform: translateX(0px);
}

.slide-both-enter-active,
.slide-both-leave-active {
    transition: all 0.4s ease-in-out;
}

header {
    width: 100%;
    display: flex;
    min-height: 56px;
    position: absolute;
    top: 0;
    background-color: #56bb8f;
    z-index: 2;
}

.header {
    width: 100%;
    align-items: center;
    padding: 5px 20px;
    display: flex;
    border-bottom: 1px solid var(--cinza);
}

.header p {
    font-size: 16px;
}

.header button a {
    background-color: #fff;
}

#logo {
    /* display: flex; */
    display: none;
}

#logo img {
    width: 70px;
}

.btnMenu {
    cursor: pointer;
    margin-right: auto;
    margin-left: 0;
}

.menu {
    margin-right: auto;
    margin-left: auto;
}

.menu strong {
    text-decoration: none;
    color: #fff;
    font-weight: bold;
    font-size: 1.5rem;
    text-align: center;
}

.header a img {
    width: 25px;
}

.header button {
    cursor: pointer;
    border: none;
}

.arrow-back {
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-right: 0;
    margin-left: auto;
}

footer {
    width: 100%;
    position: fixed;
    bottom: 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    background-color: var(--vermelho);
    box-shadow: 0 0 5px #cdcdcd;
}

.footer-logo {
    width: 100%;
    position: fixed;
    display: flex;
    bottom: 0;
    padding: 10px;
    background-color: #fff;
    box-shadow: 0 0 5px #a8a8a8;
    place-items: center;
    place-content: center;
}

footer div {
    display: flex;
}

footer img {
    width: 120px;
}

.format-footer {
    place-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 12px;
}

.format-footer h3 {
    font-size: 15px;
    margin-top: 5px;
    color: var(--branco);
    font-weight: 500;
}

#btnRefresh {
    background-color: transparent;
    display: flex;
}

#btnRefresh:hover {
    cursor: pointer;
}

#btnRefresh img {
    width: 35px;
}

#btnRefresh.refresh-none {
    display: none;
}

#attNumber {
    border-radius: 30%;
    position: absolute;
    bottom: 3px;
    font-size: 10px;
    right: 3px;
    background-color: #ef6969;
    color: #fff;
    padding: 3px;
}

.btn-save {
    display: flex;
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 0;
    background-color: #fff;
    width: 100%;
    box-shadow: 0 0 5px #cdcdcd;
    justify-content: flex-end;
}

.salvar {
    cursor: pointer;
    min-width: 64px;
    border: none;
    padding: 20px 45px;
    background-color: #fff;
    color: var(--verde);
    font-weight: 600;
    font-size: 20px;
}

.salvar:hover {
    box-shadow: none;
    background-color: var(--azul-escuro);
    color: #fff;
}

.left-drawer {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 25%;
    border-right: 1px solid #cdcdcd;
    background: #151617;
    z-index: 200;
}

.container_modal_suporte {
    position: absolute;
    left: 0;
    bottom: 0;
    top: 0;
    right: 0;
    z-index: 300;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: start;
    padding: 0 2%;
}

.modal_suporte {
    background-color: #fff;
    margin-top: 20%;
    border-radius: 10px;
    padding: 15px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.titulo_suporte {
    font-weight: bold;
    font-size: 22px;
    text-align: center;
}

.buttons_suporte {
    display: flex;
    justify-content: space-around;
    margin-top: 2%;
    width: 100%;
}

.btn_suporte {
    padding: 5px 10px;
    border-radius: 10px;
    font-size: 16px;
    font-weight: bold;
    text-decoration: none;
    color: #fff;
    background-color: #56bb8f;
    cursor: pointer;
    transition: box-shadow 1s;
}

.cancelar_suporte {
    background-color: #ef6969;
}

.btn_suporte:hover {
    box-shadow: 2px 3px 2px rgba(0, 0, 0, 0.7);
}

.containerMenuDrawer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15%;
    width: 100%;
    padding: 10px 10px;
    /* box-shadow: 0 3px 2px #fff; */
}

.container_functions {
    max-height: 83%;
    overflow: scroll;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
}

.btnLogo {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.menu_logo {
    width: 50%;
}

.activeRoute {
    color: #56b88f;
}

.activeText {
    color: #fff;
}

.container_options {
    margin: 10px 0;
}

.title_options {
    padding: 5px 10px;
    font-size: 16px;
    text-align: left;
    color: var(--verde);
    font-weight: 700;
}

.btnRouter {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 5px 10px;
    padding: 1.5vh 2vh;
    border-radius: 10px;
    transition: box-shadow 1s;
    cursor: pointer;
    box-shadow: 0 3px 2px #fff;
}

.btnRouter h1 {
    font-size: 14px;
    padding-left: 10px;
    text-align: left;
    color: #fff;
    font-weight: 500;
}

.btnRouter:hover {
    box-shadow: 0 3px 2px #1a905d;
}

.isRoute {
    background-color: var(--verde);
}

.btnSair {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    height: 7.5%;
    border-top: 1px solid #fff;
    padding-left: 15px;
}

.btnSair h1 {
    color: #ef6969;
    font-size: 20px;
    font-weight: bold;
    margin-right: auto;
    transition: all 0.5s;
}

.btnSair:hover h1 {
    font-size: 24px;
}

@media (max-width: 319px) {
    .header p {
        font-size: 14px;
    }

    #logo img {
        width: 50px;
    }

    .header a img {
        width: 22px;
    }

    #btnRefresh img {
        width: 22px;
    }
}

@media (max-width: 300px) {
    #logo {
        display: none;
    }
}
</style>
